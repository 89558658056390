import { Module } from 'vuex';
import { ElMessage, ElNotification } from 'element-plus';

import {
  accountLoginAcrion,
  accountRegisterAcrion
} from '@/service/login/login';

import localCache from '@/utils/cache';
import { ILoginAccount, IRegisterAccount } from '@/service/login/type';

import { ILoginState } from './types';
import { IRootState } from '../types';
import router from '@/router';

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      userInfo: {}
    };
  },
  getters: {},
  mutations: {
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    // 用户登录请求
    async accountLoginAcrion({ commit }, payload: ILoginAccount) {
      const { isKeepPassword, username, password } = payload;
      const userInfo = await accountLoginAcrion(payload);
      if (userInfo.message) {
        ElMessage({
          showClose: true,
          message: userInfo.message,
          type: 'error'
        });
      } else {
        if (isKeepPassword) {
          localCache.setCache('username', username);
          localCache.setCache('password', password);
        } else {
          localCache.removeCache('username');
          localCache.removeCache('password');
        }

        commit('changeUserInfo', userInfo);
        localCache.setCache('userInfo', userInfo);
        router.replace({ path: '/main/system/index' });

        ElNotification({
          title: 'MC九州',
          dangerouslyUseHTMLString: true,
          message: `<strong>欢迎您回来：<i style='color: rgb(130, 187, 93)'>${username}！</i></strong>`,
          offset: 50
        });
      }
    },
    async accountRegisterAcrion({ commit }, payload: IRegisterAccount) {
      const userInfo = await accountRegisterAcrion(payload);
      if (userInfo.type == 'success') {
        ElMessage({
          message: userInfo.message,
          type: 'success'
        });
        localCache.removeCache('username');
        localCache.removeCache('password');

        router.go(0);
      } else if (userInfo.type == 'error') {
        ElMessage.error(`${userInfo.message}`);
      }
    }
  },
  modules: {}
};

export default loginModule;
