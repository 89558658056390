let BASE_URL: string;
const TIME_OUT: number = 3 * 1000;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://localhost:8080';
  // BASE_URL = 'https://api.moonriver.city';
  // BASE_URL = '/api';
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://api.moonriver.city';
  // BASE_URL = 'http://localhost:8080';
} else {
  BASE_URL = 'https://api.moonriver.city/test';
}

export { BASE_URL, TIME_OUT };
