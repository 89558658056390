import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw
  // createWebHistory
} from 'vue-router';

import localCache from '@/utils/cache';

const Main = () => import('@/views/main/main.vue');
const Login = () => import('@/views/login/login.vue');
const NotFound = () => import('@/views/not_found/not_found.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/main/system/index'
  },
  {
    path: '/main',
    name: 'main',
    component: Main
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from) => {
  document.documentElement.scrollTop = 0;
  if (to.path !== '/login') {
    const userInfo = localCache.getCache('userInfo');
    if (!userInfo) {
      to.path = '/main/systen/index';
    }
  }
});

export default router;
