import DATARequest from './request/index';
import localCache from '@/utils/cache';
import { BASE_URL, TIME_OUT } from './request/config';

const dataRequest = new DATARequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  showLoading: true,
  interceptors: {
    requestInterceptor: (config) => {
      // config.headers['Content-Type'] = 'application/json;charset=utf-8';
      // config.headers['Access-Control-Allow-Origin'] = '*';
      // config.headers['Access-Control-Allow-Methods'] = 'PUT, POST, GET, DELETE';
      // config.headers['Access-Control-Allow-Private-Network'] = true;

      // 携带token的拦截
      // const token = localCache.getCache('token');
      // if (token) {
      //   config.headers.Authorization = `Bearer ${token}`;
      // }

      // console.log('请求成功！');
      return config;
    },
    responseInterceptor: (res) => {
      // console.log('响应成功！');
      return res.data;
    }
  }
});

const uploadFiles = new DATARequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  showLoading: true,
  interceptors: {
    requestInterceptor: (config) => {
      config.headers['Content-Type'] = 'multipart/form-data';
      return config;
    },
    responseInterceptor: (res) => {
      return res.data;
    }
  }
});

export { dataRequest, uploadFiles };
