import { Module } from 'vuex';

import {
  getPlateType,
  getPlatePostData,
  getPostData
} from '@/service/plate/plate';

import { IPlateType, IPlatePostData, IPostData } from '@/service/plate/type';

import { IPlateState } from './types';
import { IRootState } from '../types';

const loginModule: Module<IPlateState, IRootState> = {
  namespaced: true,
  state() {
    return {
      plateInfo: {},
      platePostData: [],
      platePostDataTotal: [],
      postData: {}
    };
  },
  getters: {},
  mutations: {
    getPlateType(state, plateInfo) {
      state.plateInfo = plateInfo;
    },
    getPlatePostData(state, platePostData) {
      state.platePostData = platePostData;
    },
    getlatePostDataTotal(state, platePostData) {
      state.platePostDataTotal.push(platePostData);
    },
    changelatePostDataTotal(state, platePostData) {
      state.platePostDataTotal = platePostData;
    },
    getPostData(state, postData) {
      state.postData = postData;
    },
    getComments(state, postData) {
      const { comment, total } = postData;
      setTimeout(() => {
        state.postData.comments.comment = comment;
        state.postData.comments.total = total;
      }, 500);
    }
  },
  actions: {
    async getPlateType({ commit }, payload: IPlateType) {
      const plateType = await getPlateType(payload);

      commit('getPlateType', plateType);
    },
    async getPlatePostData({ commit }, payload: IPlatePostData) {
      const platePostData = await getPlatePostData(payload);
      const changeData = {
        type: payload.plateType,
        to: payload.to,
        platePostData
      };

      if (payload.flag) {
        commit('getlatePostDataTotal', changeData);
      } else {
        commit('getPlatePostData', platePostData);
      }
    },
    async getPostData({ commit }, payload: IPostData) {
      const postData = await getPostData(payload);

      commit('getPostData', postData);
    }
  },
  modules: {}
};

export default loginModule;
