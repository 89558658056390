import { RouteRecordRaw } from 'vue-router';

export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = [];

  // 1.默认加载所有的路由
  const allRoutes: RouteRecordRaw[] = [];
  const routeFiles = require.context('../router/main', true, /\.ts/);

  routeFiles.keys().forEach((key) => {
    // console.log(key);
    const route = require('../router/main' + key.split('.')[1]);
    allRoutes.push(route.default);
  });

  return allRoutes;
}
