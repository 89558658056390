import { Module } from 'vuex';

import { getComments } from '@/service/comments/comments';

import { IgetComments } from '@/service/comments/type';

import { IPlateState } from './types';
import { IRootState } from '../types';

const loginModule: Module<IPlateState, IRootState> = {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getComments({ commit }, payload: IgetComments) {
      const plateType = await getComments(payload);
      commit('plate/getComments', plateType, { root: true });
    }
  },
  modules: {}
};

export default loginModule;
