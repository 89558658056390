import store from '@/store/index';

const index = () => import('@/views/main/system/index/index.vue');

export default {
  path: '/main/system/index',
  name: 'index',
  component: index,
  children: [],
  beforeEnter: () => {
    store.state.rerendering = true;
    store.commit('plate/changelatePostDataTotal', []);
  }
};
