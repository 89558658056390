import { dataRequest } from '../index';
import { ILoginAccount, IRegisterAccount, IDataType } from './type';

enum LoginAPI {
  accountLoginAcrion = '/account/accountLogin',
  accountRegisterAcrion = '/account/accountRegister'
}

const accountLoginAcrion = (data: ILoginAccount) => {
  return dataRequest.post<IDataType<ILoginAccount>>({
    url: LoginAPI.accountLoginAcrion,
    data
  });
};

const accountRegisterAcrion = (data: IRegisterAccount) => {
  return dataRequest.post<IDataType<IRegisterAccount>>({
    url: LoginAPI.accountRegisterAcrion,
    data
  });
};

export { accountLoginAcrion, accountRegisterAcrion };
