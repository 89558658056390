import { dataRequest } from '../index';
import { IComments, IpublishComments, IgetComments } from './type';

enum CommentsApi {
  pushComments = '/plate/pushComments',
  getComments = '/plate/getComments'
}

const pushComments = (data: IpublishComments) => {
  return dataRequest.post<IComments<IpublishComments>>({
    url: CommentsApi.pushComments,
    data
  });
};

const getComments = (data: IgetComments) => {
  return dataRequest.post<IComments<IgetComments>>({
    url: CommentsApi.getComments,
    data
  });
};
export { pushComments, getComments };
