const userSpace = () => import('@/views/main/system/userSpace/userSpace.vue');

const friends = () =>
  import('@/views/main/system/userSpace/friends/friends.vue');
const comments = () =>
  import('@/views/main/system/userSpace/comments/comments.vue');
const release = () =>
  import('@/views/main/system/userSpace/release/release.vue');

const userInfo = () =>
  import('@/views/main/system/userSpace/userInfo/userInfo.vue');
const appellationList = () =>
  import('@/views/main/system/userSpace/appellationList/appellationList.vue');
const changePassword = () =>
  import('@/views/main/system/userSpace/changePassword/changePassword.vue');
const outLogin = () =>
  import('@/views/main/system/userSpace/outLogin/outLogin.vue');

export default {
  path: '/main/system/userSpace',
  name: 'userSpace',
  component: userSpace,
  children: [
    {
      path: '/main/system/userSpace',
      redirect: '/main/system/userSpace/userInfo'
    },
    {
      path: '/main/system/userSpace/friends',
      name: 'friends',
      component: friends,
      children: []
    },
    {
      path: '/main/system/userSpace/comments',
      name: 'comments',
      component: comments,
      children: []
    },
    {
      path: '/main/system/userSpace/release',
      name: 'release',
      component: release,
      children: []
    },
    {
      path: '/main/system/userSpace/userInfo',
      name: 'userInfo',
      component: userInfo,
      children: []
    },
    {
      path: '/main/system/userSpace/appellationList',
      name: 'appellationList',
      component: appellationList,
      children: []
    },
    {
      path: '/main/system/userSpace/changePassword',
      name: 'changePassword',
      component: changePassword,
      children: []
    },
    {
      path: '/main/system/userSpace/outLogin',
      name: 'outLogin',
      component: outLogin,
      children: []
    }
  ]
};
