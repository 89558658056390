import { createApp } from 'vue';

import App from './App.vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css';
import 'normalize.css';
import './assets/css/index.less';

import router from './router';
import store from './store';
import { setupStore } from './store/index';

const app = createApp(App);

setupStore();
app.use(store);
app.use(router);
app.use(ElementPlus);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount('#app');

interface DataType {
  data: any;
}
