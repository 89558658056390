import { createStore } from 'vuex';

import { IRootState } from './types';

import login from './login/login';
import plate from './plate/plate';
import comments from './comments/comments';

import { mapMenusToRoutes } from '@/utils/map_menus';
import router from '@/router';

const store = createStore<IRootState>({
  state() {
    return {
      rerendering: true,
      mobileDrawerFlag: false
    };
  },
  getters: {},
  mutations: {
    rerendering(state, flag: boolean) {
      state.rerendering = flag;
    },
    mobileDrawerFlag(state, flag: boolean) {
      state.mobileDrawerFlag = flag;
    }
  },
  actions: {},
  modules: {
    login,
    plate,
    comments
  }
});

export function setupStore() {
  // store.dispatch('login/loadLocalLogin');
  // console.log(mapMenusToRoutes([]));
  const routes = mapMenusToRoutes([]);

  routes.forEach((route) => {
    router.addRoute('main', route);
  });
}

export default store;
