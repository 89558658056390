import { dataRequest, uploadFiles } from '../index';
import {
  IDataType,
  IPlateType,
  IPlatePostData,
  IReleasePost,
  IPostData
} from './type';

enum plateAPI {
  getPlateType = '/plate/getPlateType',
  getPlatePostData = '/plate/getPlatePostData',
  releasePost = '/plate/releasePost',
  getPostData = '/plate/getPostData'
}

const getPlateType = (data: IPlateType) => {
  return dataRequest.post<IDataType<IPlateType>>({
    url: plateAPI.getPlateType,
    data
  });
};

const getPlatePostData = (data: IPlatePostData) => {
  return dataRequest.post<IDataType<IPlatePostData>>({
    url: plateAPI.getPlatePostData,
    data
  });
};

const releasePost = (data: IReleasePost) => {
  return uploadFiles.post<IDataType<IPlatePostData>>({
    url: plateAPI.releasePost,
    data
  });
};

const getPostData = (data: IPostData) => {
  return dataRequest.post<IDataType<IPostData>>({
    url: plateAPI.getPostData,
    data
  });
};

export { getPlateType, getPlatePostData, releasePost, getPostData };
