import store from '@/store/index';

const postInformation = () =>
  import('@/views/main/system/postInformation/postInformation.vue');

export default {
  path: '/main/system/postInformation/:plateId/:postId/:flag',
  name: 'postInformation',
  component: postInformation,
  children: [],
  beforeEnter: () => {
    store.state.rerendering = true;
    store.commit('plate/changelatePostDataTotal', []);
  }
};
