<template>
  <div class="app">
    <el-scrollbar>
      <router-view></router-view>
    </el-scrollbar>
  </div>
</template>

<style lang="less">
.app {
  height: 100%;
  user-select: none;
}
</style>
