import axios from 'axios';
import { AxiosInstance } from 'axios';

import { ElLoading } from 'element-plus';
import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading';

import { DataRequestInterceptors, DataRequestConfig } from './type';

class DataRequest {
  instance: AxiosInstance;
  interceptors?: DataRequestInterceptors;
  showLoading?: boolean;
  loading?: LoadingInstance;

  constructor(config: DataRequestConfig) {
    // 添加单独的拦截器
    this.instance = axios.create(config);
    this.showLoading = config.showLoading;
    this.interceptors = config.interceptors;

    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    );

    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    );

    // 添加所有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // console.log('所有，请求拦截成功');
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: '正在请求数据...',
            background: 'rgb(0, 0, 0, .5)'
          });
        }

        return config;
      },
      (error) => {
        // console.log('所有，请求拦截失败');
        return error;
      }
    );

    this.instance.interceptors.response.use(
      (config) => {
        // console.log('所有，响应拦截成功');
        this.loading?.close();

        return config;
      },
      (error) => {
        // console.log('所有，响应拦截失败');
        this.loading?.close();

        if (error.response.status == 404) {
          console.log('404错误');
        }
        return error;
      }
    );
  }

  request<T>(config: DataRequestConfig): Promise<T> {
    // if (config.interceptors?.requestInterceptor) {
    //   config = config.interceptors.requestInterceptor(config);
    // }

    return new Promise((resolve, reject) => {
      this.instance
        .request<any, T>(config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject();
          return error;
        });
    });
  }

  get<T>(config: DataRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' });
  }

  post<T>(config: DataRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' });
  }

  delete<T>(config: DataRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' });
  }

  patch<T>(config: DataRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' });
  }
}

export default DataRequest;
